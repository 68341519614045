<template>
  <v-btn
    :icon="steps === 0"
    text
    class=""
    @click="incrementStep()"
  >
    <v-icon
      size="20"
      :left="steps === 1"
      color="blue-grey"
      v-text="icons[steps]"
    />
    <span v-if="steps === 1">Confirmar</span>
  </v-btn>
</template>
<script>
export default {
  name: 'BtnDelete',
  data: () => ({
    steps: 0,
    icons: [
      'mdi-trash-can-outline',
      'mdi-check',
    ]
  }),
  computed: {
    confirmed() {
      return this.steps === 2
    }
  },
  methods: {
    incrementStep() {
      this.steps++
      if(this.confirmed){
        this.$emit('confirmed', true)
        this.steps = 0
      }
    }
  }
}
</script>
